@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

$primary-color: black;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background: $primary-color;
  color: #fff;
  line-height: 1.5;
}

ul {
  list-style: none;
}

header {
	background: rgba(0, 47, 77, .3);
	height: 70px;
	padding: 0 10px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 12;
  box-sizing: border-box;
  h1 {
    line-height: 70px;
  }
}

.s1, .s2, .s3, .s4 {
  height: 100vh;
  margin: 0 auto;
  padding: 0 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.s1 {
  background: url(../images/osman-rana-G7VN8NadjO0-unsplash.jpg);
  background-size: cover;
  text-shadow: 1px 1px #333;
  font-size: 1rem;
}
.s2 {
  background: url(../images/skye-studios-YQtF-4DhgDM-unsplash.jpg);
  background-size: cover;
  text-shadow: 1px 1px #333;
  font-size: 2em;
}
.s3 {
  color: black;
  background: url(../images/razvan-chisu-Ua-agENjmI4-unsplash.jpg);
  background-size: cover;
  text-shadow: 1px 1px #DDD;
  font-size: 2em;
}

.leftHalf {
  padding: 20px;
  position: absolute;
  left: 0px;
  width: 30%;
  h1 {
    font-size: 3rem;
    line-height: 1;
    padding-bottom: 20px;
  }
}

// Nav styling

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.nav-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  li a {
    display: inline-block;
    color: #fff;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
    text-decoration: none;
  }
}

.fp-auto-height {
  height: auto !important;
}